import {
  Box,
  Callout,
  Container,
  Grid,
  List,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { AliceCard, AliceCardIssuingPlatform } from '../../../graphql/generated.types'
import { PageBody } from '../../../routes/PageBody'
import { UserAddressDisplay } from '../../profile/address/AddressDisplay'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { AliceCardInfo } from './AliceCardInfo'
import { AliceCardItem } from './AliceCardItem'
import { MigrateSynapseToStripeForm, useMayMigrateSynapseToStripe } from './MigrateSynapseToStripe'
import { RequestAliceCardForm, RequestFormProps } from './RequestAliceCardForm'
import {
  DISPLAY_ACCOUNT_STATUSES,
  isAliceCardAccountBalanceGated,
  sortCards,
  useAliceCardRequestScope,
  findActiveCardAccount,
} from './aliceCardUtils'
import { formatCentsAsDollars } from '../../../../utils/formatters/moneyFormat'
import { getMonthFromDate } from '../../../../utils/dateUtils'
import { DigitalWalletInfo } from './instant/DigitalWalletInfo'
import { BalanceCalculationInfo } from './BalanceCalculationInfo'
import { useLocaleState } from '../../../../translations/LocaleProvider'

/**
 * The form description is dependent on whether the user is requesting an instant card, a physical card, or both.
 */
const RequestFormDescription = ({ scope }: RequestFormProps) => {
  switch (scope) {
    case 'instant_and_physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.request_an_alice_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.ordering_your_alice_card" />
          </Typography>
        </>
      )
    case 'physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card_description" />
          </Typography>
        </>
      )
    case 'instant':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_instant_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.order_instant_card_description" />
          </Typography>
        </>
      )
    default:
      return null
  }
}

/**
 * The 'Alice Card homepage' - displays available cards,
 */
export const AliceCardList = () => {
  const {
    data: { aliceCardAccounts },
    isLoading,
  } = useQuerySpendingConnections()
  const requestScope = useAliceCardRequestScope()
  const doDisplayMigrationForm = useMayMigrateSynapseToStripe()
  const displayAccounts =
    aliceCardAccounts?.filter((account) => DISPLAY_ACCOUNT_STATUSES.includes(account.status)) || []
  const hasBalanceGatedAccount = displayAccounts.some(isAliceCardAccountBalanceGated)

  const notSynapse = (card: AliceCard) => card.issuingPlatform !== AliceCardIssuingPlatform.Synapse
  const cardsToDisplay = displayAccounts.flatMap((account) => account.aliceCards.filter(notSynapse)) || []
  const activeAliceCardAccount = findActiveCardAccount(aliceCardAccounts)
  const doShowBalance = !!activeAliceCardAccount
  const [language] = useLocaleState()

  if (isLoading) {
    return (
      <PageBody>
        <Container>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.loading" />
          </Typography>
        </Container>
      </PageBody>
    )
  }

  cardsToDisplay.sort(sortCards)

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="cards.alice.list.heading" />
        </Typography>
      </Container>
      <MigrateSynapseToStripeForm />
      <Container>
        <Typography gutterBottom>
          <FormattedMessage id="cards.alice.list.summary" />
        </Typography>
        <Callout component="div" gutterBottom>
          <Typography gutterBottom>
            <AliceCardInfo>
              <FormattedMessage id="cards.alice.what_can_i_do_with_an_alice_card" />
            </AliceCardInfo>
          </Typography>
          <Typography>
            <DigitalWalletInfo>
              <FormattedMessage id="cards.alice.suggest_adding_to_digital_wallets" />
            </DigitalWalletInfo>
          </Typography>
        </Callout>

        {hasBalanceGatedAccount && (
          <Callout gutterBottom>
            <FormattedMessage id="cards.alice.balance_gated_account_info" />
          </Callout>
        )}
      </Container>

      {cardsToDisplay.length > 0 && (
        <Container>
          <Paper sx={{ my: 2 }}>
            <Box p={2}>
              <Typography variant="h3" gutterBottom>
                <FormattedMessage id="cards.personal.billing_address" />
              </Typography>
              <UserAddressDisplay />
            </Box>
          </Paper>
          <Grid container component={List} spacing={2} justifyContent="space-between">
            {cardsToDisplay.map((card) => (
              <AliceCardItem key={card.last4} card={card} />
            ))}
          </Grid>

          {doShowBalance && (
            <Paper>
              <Box p={2}>
                <Typography variant="h3" gutterBottom>
                  <BalanceCalculationInfo>
                    <FormattedMessage id="cards.alice.remaining_balance" />
                  </BalanceCalculationInfo>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <FormattedMessage id="cards.alice.save_up_to" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage
                    id="cards.alice.so_far_this_month"
                    values={{
                      month: getMonthFromDate(language, activeAliceCardAccount.balanceMonthStart),
                    }}
                  />
                  :
                </Typography>
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <FormattedMessage id="cards.alice.spent" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="cards.alice.remaining" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell>
                        <FormattedMessage id="benefits.mass_transit" />
                      </TableCell>
                      <TableCell>{formatCentsAsDollars(activeAliceCardAccount.usedMassTransit)}</TableCell>
                      <TableCell>{formatCentsAsDollars(activeAliceCardAccount.balanceMassTransit)}</TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell>
                        <FormattedMessage id="benefits.parking" />
                      </TableCell>
                      <TableCell>{formatCentsAsDollars(activeAliceCardAccount.usedParking)}</TableCell>
                      <TableCell>{formatCentsAsDollars(activeAliceCardAccount.balanceParking)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Container>
      )}
      {requestScope && !doDisplayMigrationForm && (
        <Container sx={{ my: 2 }}>
          <Box p={2}>
            <RequestFormDescription scope={requestScope} />
            <RequestAliceCardForm scope={requestScope} />
          </Box>
        </Container>
      )}
    </PageBody>
  )
}
